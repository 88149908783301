import { createTheme, ThemeOptions } from '@mui/material';
import { green, lightBlue, red } from '@mui/material/colors';

import OptusWhiteLogo from '../../components/OptusWhiteLogo';
import Optus from '../../components/Optus';

// Optus Theme

const theme: ThemeOptions = {
  palette: {
    primary: {
      main: '#00828e',
      contrastText: '#fff',
      light: '#00828e',
    },
    secondary: {
      main: '#fdcc08',
      dark: '#e9ba02',
    },
    success: {
      main: green[600],
      dark: green[500],
    },
    error: {
      main: red[500],
      dark: red[500],
    },
    info: {
      main: lightBlue[600],
      dark: lightBlue[500],
    },
  },
  typography: {
    fontFamily: 'Poppins',
    fontSize: 13,
    htmlFontSize: 17,
    button: {
      fontSize: '.9em',
      lineHeight: 2,
      letterSpacing: '0.08em',
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '.9em',
        },
      },
    },
  },
};
export const appTheme = createTheme(theme);

// Optus app theme settings
export const appThemeSettings = {
  loginLogo: Optus,
  loginTitle: 'BroadCloud to RingCentral Migration',
  favicon: '../../../public/favicon.ico',
  pageTitle: 'BroadCloud 2 RingCentral - Optus',
  customerColumnLabel: 'Jarvis Id',
  businessCenterLabel: 'Optus Business Center',
  name: 'Optus',
  backgGroundPathFill1: '#3ca6ad',
  backgGroundPathFill2: '#3ca6ad',
  headerLogo: OptusWhiteLogo,
};
